import React, { forwardRef } from "react";

import { twMerge } from "tailwind-merge";

import { Dot } from "../Dot/Dot";

export const BrowserWindow = forwardRef(function BrowserWindow(
  { children, className, mobile = false },
  ref
) {
  return (
    <div
      ref={ref}
      className={`${twMerge(`flex flex-col shadow-lg rounded-lg overflow-hidden ${mobile ? "w-[375px]" : "w-[1024px]"}`, className)}`}
    >
      <div className={`h-8 w-full flex flex-0 items-center bg-grey`}>
        <div className={`${mobile ? "hidden" : "flex"} gap-1 pl-4`}>
          <Dot type="danger" />
          <Dot type="help" />
          <Dot type="success" />
        </div>
      </div>

      <div className="flex-1">{children}</div>
    </div>
  );
});

export const Preview = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-1/4">
        <BrowserWindow mobile>
          <div className="flex align-center justify-center">
            <p>Mobile</p>
          </div>
        </BrowserWindow>
      </div>

      <div className="w-1/2">
        <BrowserWindow>
          <div className="flex align-center justify-center">
            <p>Desktop</p>
          </div>
        </BrowserWindow>
      </div>
    </div>
  );
};
