// import "babel-polyfill";
import { mapKeys, snakeCase } from "lodash";
import CreateReferral from "models/CreateReferral";
import CreateReferrer from "models/CreateReferrer";
import Friend from "models/Friend";
import { ReferralTimeline } from "models/ReferralTimeline";
import Referrer from "models/Referrer";
import SearchResult from "models/SearchResult";
import { User } from "models/User";

class Users {
  constructor(market, axios) {
    this.market = market;
    this.axios = axios;
  }

  async search(query) {
    const resp = await this.axios.get("/search", {
      params: {
        query,
        market_id: this.market.id,
      },
    });

    return resp.data.payload.map((r) => new SearchResult(r));
  }

  async find(id, dnaFormat = false) {
    const resp = await this.axios.get(`/users/${id}${dnaFormat ? "?format=dna" : ""}`);

    if (dnaFormat) return User.from(this.market, resp.data.payload);

    if (resp.data.payload.user_type === "customer") {
      return new Referrer(this.market, resp.data.payload);
    }
    return new Friend(this.market, resp.data.payload);
  }

  async getTimeline(userId) {
    const resp = await this.axios.post(`/events/email_timeline`, {
      market_id: this.market.id,
      user_id: userId,
    });

    return resp.data.payload.map((m) => ReferralTimeline.from(m));
  }

  async getPartners() {
    return this.axios.get("/partners", { params: { market_id: this.market.id } });
  }

  async getPartner(id) {
    return this.axios.get(`/users/${id}`, { params: { market_id: this.market.id } });
  }

  async updatePartner(
    id,
    firstName,
    lastName,
    email,
    mobile,
    mobileCountryCode,
    locale,
    signupReason,
    businessName,
    jobTitle,
    industrySector,
    businessCountry,
    website,
    twitter,
    instagram,
    facebook,
    linkedin,
    youtube,
    snapchat,
    audienceSize,
    audienceLocation
  ) {
    try {
      const resp = await this.axios.patch(`/partners/${id}`, {
        id,
        market_id: this.market.id,
        business_name: businessName,
        first_name: firstName,
        last_name: lastName,
        email,
        mobile,
        mobile_country_code: mobileCountryCode,
        locale,
        job_title: jobTitle || null,
        industry_sector: industrySector || null,
        business_country: businessCountry || null,
        website: website || null,
        twitter_handle: twitter || null,
        instagram_handle: instagram || null,
        facebook_handle: facebook || null,
        linkedin_handle: linkedin || null,
        youtube_handle: youtube || null,
        snapchat_handle: snapchat || null,
        signup_reason: signupReason,
        audience_size: audienceSize || null,
        audience_location: audienceLocation,
      });

      return resp;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async createPartnerLocale(partnerId, locale, avatar, banner, quote, displayName, offerHeader) {
    try {
      const resp = await this.axios.post(`/partners/${partnerId}/partner_locales`, {
        locale,
        avatar,
        banner,
        quote,
        display_name: displayName,
        offer_header: offerHeader,
      });

      return resp;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async updatePartnerLocale(
    partnerId,
    partnerLocaleId,
    avatar,
    banner,
    quote,
    displayName,
    offerHeader
  ) {
    try {
      const resp = await this.axios.patch(
        `/partners/${partnerId}/partner_locales/${partnerLocaleId}`,
        {
          avatar,
          banner,
          quote,
          display_name: displayName,
          offer_header: offerHeader,
        }
      );

      return resp;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async updateUser(id, attributes = {}) {
    try {
      const userAttributes = mapKeys(attributes, (v, k) => snakeCase(k));

      const resp = await this.axios.patch(`/users/${id}`, userAttributes);

      return resp.data.payload;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async updateDetails(id, firstName, lastName, email, customFields) {
    const cfData = customFields.reduce((group, customField) => {
      const result = { ...group };
      const { campaignId } = customField;
      result[campaignId] = result[campaignId] ?? [];
      result[campaignId].push({
        key: customField.label,
        value: customField.value,
      });
      return result;
    }, {});

    try {
      const resp = await this.axios.patch(`/users/${id}`, {
        first_name: firstName,
        last_name: lastName,
        email,
        custom_fields: cfData,
      });

      return resp.data.payload;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async createNewReferrer(
    campaignId,
    firstName,
    lastName,
    email,
    customFields,
    termsOptIn,
    emailComplianceOptIn,
    marketingOptIn
  ) {
    try {
      const resp = await this.axios.post("/referrers", {
        campaign_id: campaignId,
        first_name: firstName,
        last_name: lastName,
        email,
        custom_fields: customFields,
        terms_opt_in: termsOptIn,
        email_compliance_opt_in: emailComplianceOptIn,
        marketing_opt_in: marketingOptIn,
      });

      return new CreateReferrer(resp.data.payload);
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async createNewReferral(
    campaignId,
    firstName,
    lastName,
    email,
    customFields,
    termsOptIn,
    marketingOptIn,
    referrerEmail,
    orderNumber,
    rewardCategory,
    force
  ) {
    try {
      const resp = await this.axios.post("/referrals/track", {
        campaign_id: campaignId,
        force,
        referrer_email: referrerEmail,
        first_name: firstName,
        last_name: lastName,
        email_address: email,
        marketing_opt_in: marketingOptIn,
        terms_opt_in: termsOptIn,
        custom_fields: customFields,
        order_number: orderNumber,
        reward_category: rewardCategory,
      });

      return new CreateReferral(resp.data.payload);
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async reconcileReferral(marketId, orderNumber, status, rewardCategory) {
    try {
      const resp = await this.axios.post("/referrals/reconcile", {
        market_id: marketId,
        order_number: orderNumber,
        status,
        reward_category: rewardCategory || undefined,
      });

      return resp.data.payload;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async emailUnsubscribe(id) {
    try {
      const resp = await this.axios.post(`/users/${id}/unsubscribe`);

      return resp.data.payload;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async emailSubscribe(id) {
    try {
      const resp = await this.axios.post(`/users/${id}/subscribe`);

      return resp.data.payload;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async resendFulfillment(id, fulfillmentId) {
    try {
      const resp = await this.axios.post(`/users/${id}/resend_reward_email`, {
        fulfilment_id: fulfillmentId,
      });

      return resp.data.ok;
    } catch (e) {
      throw e.response.data.payload;
    }
  }

  async createFulfillment(rewardTypeId, customerId) {
    try {
      const resp = await this.axios.post(`/users/${customerId}/create_fulfilment`, {
        reward_type_id: rewardTypeId,
      });

      return resp.data.payload;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async createFulfillmentForReferral(rewardTypeId, userId, referralId) {
    try {
      const resp = await this.axios.post(`/users/${userId}/create_fulfilment_for_referral`, {
        reward_type_id: rewardTypeId,
        referral_id: referralId,
      });

      return resp.data.payload;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async createNewPartner(
    campaignId,
    firstName,
    lastName,
    email,
    mobile,
    mobileCountryCode,
    locale,
    signupReason,
    businessName,
    jobTitle = null,
    industrySector = null,
    businessCountry = null,
    website = null,
    twitter = null,
    instagram = null,
    facebook = null,
    linkedin = null,
    youtube = null,
    snapchat = null,
    audienceSize = null,
    audienceLocation = null
  ) {
    try {
      const resp = await this.axios.post("/partners", {
        campaign_id: campaignId,
        business_name: businessName,
        first_name: firstName,
        last_name: lastName,
        email,
        mobile,
        mobile_country_code: mobileCountryCode,
        locale,
        ...(jobTitle && { job_title: jobTitle }),
        ...(industrySector && { industry_sector: industrySector }),
        ...(businessCountry && { business_country: businessCountry }),
        ...(website && { website }),
        ...(twitter && { twitter_handle: twitter }),
        ...(instagram && { instagram_handle: instagram }),
        ...(facebook && { facebook_handle: facebook }),
        ...(linkedin && { linkedin_handle: linkedin }),
        ...(youtube && { youtube_handle: youtube }),
        ...(snapchat && { snapchat_handle: snapchat }),
        ...(signupReason && { signup_reason: signupReason }),
        ...(audienceSize && { audience_size: audienceSize }),
        ...(audienceLocation && { audience_location: audienceLocation }),
      });

      return resp;
    } catch (err) {
      throw err.response.data.payload;
    }
  }

  async validateEmail(email_value, partnerId) {
    try {
      const resp = await this.axios.put("/partners/validate_partner_fields", {
        market_id: this.market.id,
        fields: { email: email_value },
        partner_id: partnerId,
      });
      return resp;
    } catch (exception) {
      throw exception.response.data.payload;
    }
  }

  async updateExpiryDate(fulfillmentId, expiryDate) {
    try {
      const resp = await this.axios.post(`/fulfilments/${fulfillmentId}/update_expiry_date`, {
        market_id: this.market.id,
        expiry_date: expiryDate,
      });

      return resp.data.payload;
    } catch (e) {
      throw e.response.data.payload;
    }
  }

  async unexpireFulfilment(fulfillmentId, expiryDate) {
    try {
      const resp = await this.axios.post(`/fulfilments/${fulfillmentId}/unexpire`, {
        market_id: this.market.id,
        expiry_date: expiryDate,
      });

      return resp.data.payload;
    } catch (e) {
      throw e.response.data.payload;
    }
  }

  async expireFulfilment(fulfillmentId) {
    try {
      const resp = await this.axios.post(`/fulfilments/${fulfillmentId}/expire`, {
        market_id: this.market.id,
      });

      return resp.data.payload;
    } catch (e) {
      throw e.response.data.payload;
    }
  }
}

export default Users;
