import { FaBullhorn } from "react-icons/fa6";
import { twMerge } from "tailwind-merge";

import { Spinner } from "../Spinner/Spinner";

export const buttonBase =
  "rounded-2xl py-2 px-6 flex align-middle justify-center";
export const buttonDisabled =
  "cursor-default bg-mid-blue/50 text-white border-mid-blue/0";
export const buttonPrimary =
  "cursor-pointer bg-mid-blue text-white border-2 border-solid border-mid-blue disabled:bg-mid-blue/50 disabled:border-mid-blue/0 disabled:cursor-default";
export const buttonSecondary =
  "cursor-pointer bg-white text-mid-blue border-2 border-solid border-mid-blue";

export const Button = ({
  children,
  className,
  secondary,
  icon: Icon,
  onClick,
  submit,
  loading,
  disabled,
}) => {
  const appearanceClass = secondary ? buttonSecondary : buttonPrimary;

  return (
    <button
      className={twMerge(buttonBase, appearanceClass, className)}
      onClick={onClick}
      type={submit ? "submit" : "button"}
      disabled={loading || disabled}
    >
      {loading && <Spinner className="w-4 h-4 mr-2" />}
      {Icon && <Icon className="text-lg pr-2 flex-0" />} {children}
    </button>
  );
};

export const Preview = () => {
  return (
    <div className="flex flex-row gap-4">
      <Button>Primary</Button>
      <Button secondary>Secondary</Button>
      <Button icon={FaBullhorn}>With Icon</Button>
      <Button loading>Loading</Button>
      <Button disabled>Disabled</Button>
    </div>
  );
};
