import React from "react";

import { Timeline } from "bp-ui/src/components/Card";
import { t } from "i18next";

import { ReferralActionsForm } from "../ReferralActionsForm/ReferralActionsForm";
import {
  emptyTimelinePlaceholderEvent,
  filterTimelineData,
  getReferralStatusIconFromValue,
  getReferralStatusTypeFromValue,
  sortTimelineData,
} from "../helpers";

export const ReferralTimeline = ({
  referral,
  timelineData,
  user,
  timezoneOffset,
  selectedMarket,
  reloadData,
}) => {
  const buildTimelineEvents = () => {
    const timeline = filterTimelineData(
      timelineData,
      (f) =>
        f.orderId === referral.referrerOrderId &&
        !f.fulfilmentId &&
        f.userId === user.userId &&
        f.referralId === referral.id
    );

    const hideNumber = timeline.length === 0;

    if (timeline.length === 0) {
      timeline.push(emptyTimelinePlaceholderEvent());
    }

    timeline.push({
      hideNumber,
      status: getReferralStatusTypeFromValue(referral.status),
      icon: getReferralStatusIconFromValue(referral.status),
      title: t(`referrerProfile.referralTimeline.referral_${referral.status}`),
      timestamp: referral.statusUpdatedAt,
      content: (
        <div>{t(`referrerProfile.referralTimeline.referral_${referral.status}_description`)}</div>
      ),
    });

    return sortTimelineData(timeline);
  };

  return (
    <div className="flex flex-col w-full">
      <h2 className="text-xl mt-0 mb-8">
        {t("referrerProfile.referralInfoCard.referralHistoryLog")}
      </h2>
      <Timeline events={buildTimelineEvents()} timezoneOffset={timezoneOffset} />

      <div className="pl-16 w-2/5">
        <ReferralActionsForm
          referral={referral}
          reloadData={reloadData}
          selectedMarket={selectedMarket}
          user={user}
        />
      </div>
    </div>
  );
};
