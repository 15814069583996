import {
  FaCheck,
  FaRotate,
  FaTriangleExclamation,
  FaXmark,
} from "react-icons/fa6";

export const Statuses = {
  SUCCESS: "success",
  ERROR: "error",
  BUSY: "processing",
  OK: "pending",
  WARNING: "expired",
  DEFAULT: "paused",
};

export const StatusColours = {
  [Statuses.SUCCESS]: "dark-green",
  [Statuses.ERROR]: "red",
  [Statuses.BUSY]: "dark-blue",
  [Statuses.OK]: "mid-blue",
  [Statuses.WARNING]: "orange",
  [Statuses.DEFAULT]: "mid-grey",
};

export const StatusIcons = {
  [Statuses.SUCCESS]: FaCheck,
  [Statuses.ERROR]: FaXmark,
  [Statuses.BUSY]: FaRotate,
  [Statuses.OK]: FaCheck,
  [Statuses.WARNING]: FaTriangleExclamation,
  [Statuses.DEFAULT]: FaTriangleExclamation,
};

export const FulfilmentActions = {
  RESEND: "resend_email",
  EXPIRE: "expire",
  UNEXPIRE: "unexpire",
};

export const ReferralActions = {
  CANCEL: "cancel",
  CONFIRM: "confirm",
  REVERT: "restore",
  ISSUE_REWARD: "issue_reward",
};

export const DotTypes = {
  SUCCESS: "success",
  DANGER: "danger",
  SECONDARY: "secondary",
  INFO: "info",
  WARNING: "warning",
  HELP: "help",
  DEFAULT: "default",
};

export const DotColours = {
  [DotTypes.SUCCESS]: "green",
  [DotTypes.DANGER]: "red",
  [DotTypes.SECONDARY]: "dark-blue",
  [DotTypes.INFO]: "mid-blue",
  [DotTypes.WARNING]: "orange",
  [DotTypes.HELP]: "yellow",
  [DotTypes.DEFAULT]: "mid-grey",
};
