import React, { useState } from "react";

import { Card } from "bp-ui";
import { useTranslation } from "react-i18next";
import { FaGift, FaMoneyCheckDollar, FaRegCalendar } from "react-icons/fa6";

import FormattedDate from "components/formatted_date/FormattedDate";

import { FulfilmentTimeline } from "../FulfilmentTimeline/FulfilmentTimeline";
import { FulfilmentValue } from "../FulfilmentValue/FulfilmentValue";
import { ReferralTimeline } from "../ReferralTimeline/ReferralTimeline";
import { getFulfilmentStatusMessage } from "../helpers";
import { ReferralStatus } from "./ReferralStatus";

export const ReferralInfoDetails = ({
  fulfilments,
  timelineData,
  timezoneOffset,
  referral,
  user,
  selectedMarket,
  reloadData,
}) => {
  const { t } = useTranslation();
  const [referralCollapsed, setReferralCollapsed] = useState(true);
  const [rewardOpen, setRewardOpen] = useState([]);

  const updateRewardOpen = (value, index) => {
    const newRewardOpen = [...rewardOpen];
    newRewardOpen[index] = value;
    setRewardOpen(newRewardOpen);
  };

  return (
    <>
      {fulfilments.map((fulfilment, i) => (
        <Card.Collapse
          key={fulfilment.id}
          open={rewardOpen[i]}
          detail={
            <FulfilmentTimeline
              timelineData={timelineData}
              fulfilment={fulfilment}
              userId={user.id}
              timezoneOffset={timezoneOffset}
              selectedMarket={selectedMarket}
              reloadData={reloadData}
            />
          }
        >
          <Card.Row border={!rewardOpen[i]}>
            <div className="flex gap-6 mb-2">
              <div className="flex flex-none w-2/6 flex-row">
                <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>
                <FaGift className="text-2xl mr-3 mt-3" />
                <Card.Detail
                  border={false}
                  label={t("referrerProfile.referralInfoCard.rewardName")}
                >
                  {fulfilment.name}
                </Card.Detail>
              </div>
              <FulfilmentValue fulfilment={fulfilment} />
              <Card.Detail label={t("referrerProfile.referralInfoCard.fulfilmentDate")}>
                <FormattedDate
                  timezoneOffset={timezoneOffset}
                  type="numeral_short_with_time"
                  timestamp={fulfilment.createdAt}
                />
              </Card.Detail>
              <Card.Detail label={t("referrerProfile.referralInfoCard.rewardStatus")}>
                <ReferralStatus
                  status={fulfilment.status.value}
                  tooltip={getFulfilmentStatusMessage(fulfilment)}
                />
              </Card.Detail>
              <div className="flex flex-none justify-end">
                <Card.CollapseButton
                  onClick={() => updateRewardOpen(!rewardOpen[i], i)}
                  collapsed={!rewardOpen[i]}
                  expandSectionLabel={t("global.expandSection")}
                  collapseSectionLabel={t("global.collapseSection")}
                />
              </div>
            </div>
          </Card.Row>
        </Card.Collapse>
      ))}

      <Card.Collapse
        open={!referralCollapsed}
        detail={
          <ReferralTimeline
            referral={referral}
            timelineData={timelineData}
            user={user}
            timezoneOffset={timezoneOffset}
            selectedMarket={selectedMarket}
            reloadData={reloadData}
          />
        }
      >
        <Card.Row border={false}>
          <div className="flex gap-6 mb-2">
            <div className="flex flex-none w-2/6 flex-row">
              <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>
              <FaRegCalendar className="text-2xl mr-3 mt-3" />
              <Card.Detail
                border={false}
                label={t("referrerProfile.referralInfoCard.referralDate")}
              >
                <FormattedDate
                  timezoneOffset={timezoneOffset}
                  type="numeral_short_with_time"
                  timestamp={referral.createdAt}
                />
              </Card.Detail>
            </div>
            <Card.Detail
              className="flex-2 mr-9"
              label={t("referrerProfile.referralInfoCard.orderNumber")}
            >
              {referral.orderNumber}
            </Card.Detail>
            <Card.Detail label={t("referrerProfile.referralInfoCard.referralStatus")}>
              <ReferralStatus status={referral.status} />
            </Card.Detail>
            <div className="flex flex-none justify-end">
              <Card.CollapseButton
                onClick={() => setReferralCollapsed(!referralCollapsed)}
                collapsed={referralCollapsed}
                expandSectionLabel={t("global.expandSection")}
                collapseSectionLabel={t("global.collapseSection")}
              />
            </div>
          </div>

          {referral.voucherCode && referral.voucherCode != "" ? (
            <div className="flex gap-6">
              <div className="flex flex-none w-2/6 flex-row">
                <div className="rounded-full aspect-square w-14 h-14 p-3.5 mr-10"></div>

                <FaMoneyCheckDollar className="text-2xl mr-3 mt-3" />

                <Card.Detail border={false} label={t("vouchers.tableHeaders.voucher")}>
                  {referral.voucherCode}
                </Card.Detail>
              </div>
            </div>
          ) : null}
        </Card.Row>
      </Card.Collapse>
    </>
  );
};
