export default class RewardType {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.archived = data.archived;
    this.manuallyIssuable = data.manually_issuable;
    this.manuallyIssuableWithReferral = data.manually_issuable_with_referral;
    this.userClass = data.user_class;
    this.rewardCategory = data.reward_category;
  }

  canBeIssuedTo(user) {
    return this.manuallyIssuable && user.isA(this.userClass);
  }

  canBeIssuedWithReferralTo(user) {
    return this.manuallyIssuableWithReferral && user.isA(this.userClass);
  }
}
