const passwordFormatError =
  "Le mot de passe que vous avez saisi n'est pas assez complexe, ajoutez des chiffres, des symboles ou plus de lettres pour le rendre plus complexe.";

export default {
  translation: {
    accountDetails: {
      accountCreationDate: "date de création du compte",
      accountIdentifier: "identifiant du compte",
      accountType: "type de compte",
      accountStatus: "statut du compte",
      eligibleForRewards: "éligible pour les récompenses",
      internalIdentifier: "identifiant interne",
      subAccountType: "type de sous-compte",
    },
    auth: {
      generic: "Impossible de se connecter pour le moment. Veuillez réessayer plus tard.",
      unauthorized_ip_address: "Accès refusé à partir de cette adresse IP.",
    },
    bulkActions: {
      addCustomers: {
        fileImport: {
          buttonLabel: "Ajouter des clients",
          checkboxLabel: "Les adresses de courriel sont des hashs MD5.",
        },
        header: "Ajouter des clients",
        history: {
          header: "Historique",
          tableHeaders: {
            customers: "Clients",
            existing: "Existants",
            new: "Nouveaux",
            numberOf: "Nombre de",
            processedRows: "Lignes Traitées",
            status: "Statut",
            uploadedAt: "Téléchargé À",
            uploadedBy: "Téléchargé Par",
          },
        },
        modal: {
          emailDescription:
            'L\'adresse de courriel du référent en texte brut (par ex. "john@doe.com") ou un hash MD5 d\'un adresse de courriel en minuscule (par ex. "30123074929abe9168856af4f4bc904b").',
          openButtonText: "Quelles sont les en-têtes de colonne obligatoires?",
          title: "En-têtes de colonne obligatoires",
        },
        subtitle:
          "Téléchargez une liste des adresses de courriel de clients que la plateforme utilisera pour valider l'éligibilité des référés à obtenir un bon d'achat sur leur page. Les adresses de courriel peuvent être soit en texte brut, soit des hash MD5 des adresses de courriel en minuscule. Vous pourriez avoir besoin de contacter votre Client Success Manager pour configurer cette fonctionnalité si vous l’utilisez pour la première fois.",
      },
      forgetUsers: {
        header: "Utilisateur Oublié",
        history: {
          header: "Historique",
          tableHeaders: {
            forgottenUserCount: "Compte Utilisateur Oublié",
            status: "Statut",
            uploadedAt: "Téléchargé À",
            uploadedBy: "Téléchargé Par",
          },
        },
        fileImport: {
          buttonLabel: "Télécharger un fichier CSV",
        },
        modal: {
          emailDescription: 'L\'adresse de courriel du référent par ex. "john@doe.com"',
          openButtonText:
            "Quelles sont les en-têtes de colonne obligatoires pour le téléchargement d'un fichier CSV?",
          title: "En-têtes de colonne obligatoires",
        },
        subtitle:
          "Vous pouvez télécharger une liste des adresses de courriel d'utilisateurs à supprimer de nos systèmes.Vous pouvez également saisir les adresses de courriel de chaque utilisateur, chacune sur une nouvelle ligne",
        textImport: {
          buttonLabel: "Soumettre",
          placeholder:
            "Saisissez manuellement une liste des adresses de courriel, chacun sur une nouvelle ligne.",
          invalidWarning: "Les adresses de courriel suivantes sont invalides",
        },
      },
      navigation: {
        addCustomers: "Ajouter des clients",
        forgetUsers: "Oublier les utilisateurs",
        referralManagement: "Gestion des récompenses",
        signups: "Inscriptions",
        transactionTracking: "Suivi de la Transaction",
      },
      referralManagement: {
        history: {
          header: "Historique",
          tableHeaders: {
            status: "Statut",
            uploadedAt: "Téléchargé À",
            skippedReferrals: "Ignorée",
            confirmedReferrals: "Confirmé",
            cancelledReferral: "Annulé",
            restoredReferrals: "Restauré",
          },
        },
        campaignListPlaceholder: {
          withResults: "Sélectionnez une campagne",
          noResults: "Aucun parrainage en attente dans aucune campagne",
        },
        exportButton: {
          loading: "Création en cours, veuillez patienter…",
          text: "Exporter le fichier CSV",
          errorSubmittedMessage:
            "Il y a une erreur dans le traitement du rapport, merci de reessayer.",
        },
        fileImport: {
          buttonLabel: "Importer le fichier CSV",
          checkboxLabel: "Valider les types de récompense?",
        },
        header: "Gestion des récompenses",
        referralCount: "{{count}} parrainages en attente",
        referralCount_one: "{{count}} parrainage en attente",
        stepFirstHeader: "Étape 1",
        stepFirstSubtitle:
          "Ce fichier CSV contient tous vos parrainages qui sont actuellement en attente. Les parrainages confirmés ou annulés auparavant n'apparaîtront pas ici.",
        stepSecondHeader: "Étape 2 - Modifier le statut",
        stepSecondDescription: {
          updateStatusColumn:
            "Modifiez dans le fichier CSV la valeur de la colonne <code>{{statusColumnHeader}}</code> en:",
          confirmedValueDescription: "pour le marquer comme validé",
          cancelledValueDescription: "pour le marquer comme annulé.",
          unmodifiedRowsNote:
            "N'éditez pas les enregistrements qui n'ont pas besoin d'être mis à jour.",
        },
        stepThirdHeader: "Étape 3 - Colonne facultatif",
        stepThirdDescription: {
          addRewardCategoryColumn: [
            "Ajoutez une colonne <code>{{rewardCategoryColumnHeader}}</code> au fichier CSV, en la plaçant <strong>après</strong> la colonne <code>{{statusColumnHeader}}</code>.",
            "Toutes les lignes qui n’ont pas de catégorie de récompense peuvent être laissées vides pour cette colonne.",
          ],
          addClientDataColumns: [
            "Ajouter des données clients<br>",
            "<strong>Ajouter de colonne(s):</strong> Ajouter des colonne(s) de données clients au fichier CSV après <code>{{statusColumnHeader}}</code> et <code>{{rewardCategoryColumnHeader}}</code> (facultatif). Le titre de la colonne doit être au format <code>ClientData_{name}</code>, par exemple <code>ClientData_Customer ID</code> pour <code>Customer ID</code>.",
            "<strong>Mise à jour des données:</strong> Pour mettre à jour les données existantes, assurez-vous que les titres de vos nouvelles colonne(s) <code>Client Data</code> correspondent à ceux des colonnes existantes.",
            "<strong>Pas de données:</strong> Les lignes sans <code>Client Data</code> peuvent être laissées vides.",
            "<strong>Suppression:</strong> Il n’est pas possible de supprimer les <code>Client Data</code> en utilisant ce processus.",
          ],
        },
        stepFourthHeader: "Étape 4 - Télécharger",
        stepFourthDescription: {
          requiredColumns: "Le fichier CSV doit contenir les deux colonnes suivantes:",
          optionalColumns:
            "Le fichier CSV peut également contenir une troisième colonne facultative:",
          requiredColumnSeparator: "ou",
        },
        stepFifthHeader: "Étape 5 - Finaliser",
        stepFifthSubtitle:
          "Une fois le fichier reçu, le système traite les données et distribue les récompenses appropriées aux clients.",
        subtitle:
          "Tout client effectuant un parrainage confirmé sera marqué comme étant en attente, afin que vous puissiez vous assurer que la transaction est effective avant de distribuer une récompense.",
      },
      signups: {
        campaignSelectPlaceholder: "Sélectionner une campagne",
        campaignListPlaceholder: "Il n'y a pas de campagne pour ce marché",
        fileImport: {
          buttonLabel: "Télécharger",
          checkboxLabel:
            "Envoyez un adresse de courriel d'invitation aux utilisateurs qui ne se sont pas encore inscrits (n'envoyez les adresses de courriel d'invitation que si les utilisateurs ont accepté de recevoir les adresses de courriel marketing)",
        },
        header: "Inscriptions",
        history: {
          header: "Historique",
          tableHeaders: {
            campaign: "Campagne",
            status: "Statut",
            uploadedAt: "Téléchargez À",
            uploadedBy: "Téléchargez Par",
          },
        },
        modal: {
          optional: {
            clientDataDescription:
              'Métadonnées supplémentaires spécifiées par le client et associées au référent inscrit à la campagne par ex. "ClientData_customer_id", "ClientData_phone_number". Toujours facultatif.',
            customFieldDescription:
              "Si vous utilisez un seul champ personnalisé pour identifier un client, ce champ peut contenir un élément tel qu'un ID de membre ou un numéro de téléphone. Si l'utilisateur doit saisir cette valeur lors de l'inscription, alors elle est obligatoire dans ce fichier.",
            customFieldsDescription:
              'Si vous utilisez plusieurs champs personnalisés pour identifier un client, ces champs peuvent contenir un élément tel qu’un ID de membre ou un numéro de téléphone, e.g. "CustomField_phone_number", "CustomField_membership_number". Si l’utilisateur doit saisir ces valeurs lors de l’inscription, alors elles sont obligatoires dans ce fichier.',
            header: "Configuration de la campagne en attente, facultative ou obligatoire",
            lastNameDescription: 'Le nom de la personne, par ex. "Smith". Toujours facultatif.',
            localeDescription:
              "La langue du client. Lorsqu'un client reçoit des courriels liés à la campagne de parrainage, ils s'affichent dans la langue indiquée ici. Cette option est facultative. Si aucun paramètre n'est fourni, le système utilisera le paramètre par défaut du marché",
          },
          required: {
            emailDescription: 'L\'adresse de courriel de la personne, par ex. "john@doe.com".',
            firstNameDescription: 'Le prénom de la personne, par ex."Jane".',
          },
          response: {
            dashboardUrlDescription: "L'URL unique du tableau de bord de chaque personne",
            header:
              "Une fois soumis, vous recevrez un fichier CSV mis à jour contenant les colonnes supplémentaire suivantes:",
            shareUrlDescription: "L'URL unique du tableau de bord de chaque personne",
            statusDescription:
              "Indique si une inscription a été créée ou la raison pour laquelle elle ne l'a pas été. Cela peut être dû au fait qu'ils sont déjà inscrits à la campagne ou qu'ils se sont déjà inscrits via l'API d'inscription (dans ce cas, l'URL de partage existant est inclus).",
          },
          openButtonText: "Quelles sont les en-têtes de colonne obligatoires?",
          title: "En-têtes de colonne obligatoires",
          downloadCsvDescription:
            "Téléchargez notre modèle prêt à l’emploi pour vous assurer de nous fournir les données correctes",
          downloadCsvButtonText: "Télécharger le modèle CSV",
        },
        subtitle:
          "Téléchargez un fichier CSV contenant les détails des personnes pour lesquelles vous souhaitez faire l'inscription.",
        limit: "La limite est de 200 000 lignes par téléchargement.",
        notification: "Vous serez informé par e-mail du statut de votre import.",
      },
      transactionTracking: {
        fileImport: {
          buttonLabel: "Importer les transactions",
          checkboxLabel: "Ne pas valider l'éligibilité des parrainages",
        },
        header: "Suivi de la Transaction ",
        history: {
          header: "Historique",
          tableHeaders: {
            campaign: "Campagne",
            referrals: "Parrainages",
            rejected: "Annulé",
            status: "Statut",
            successful: "Confirmé",
            transactions: "Transactions",
            unsuccessful: "Échec",
            uploadedAt: "Téléchargé À",
            uploadedBy: "Téléchargé Par",
          },
        },
        modal: {
          clientDataContent:
            "Métadonnées supplémentaires spécifiées par le client et associées au client qui a effectué la transaction, par example ClientData_numéro_de_téléphone, ClientData_numéro_de_membre",
          customFieldContent:
            "Si vous utilisez un seul champ personnalisé pour identifier les filleuls, ce champ peut contenir des informations telles qu'un numéro de membre ou un numéro de téléphone. **Remarque : si le filleul possède déjà un champ personnalisé, ce champ le remplacera. Si vous laissez cette colonne vide, l'ancienne valeur existante restera en place. Par ex. 07123456789",
          customFieldsContent:
            'Si vous utilisez plusieurs champs personnalisés pour identifier les filleuls, ces champs peuvent contenir des informations telles qu’un numéro de membre ou un numéro de téléphone e.g. "CustomField_phone_number", "CustomField_membership_number".',
          emailAddressContent: 'L\'adresse de courriel du client par ex. "jane@smith.com"',
          fullNameHContent: 'Le nom complet du client. Format "Prénom, Nom", par ex. "Jane, Smith"',
          localeContent:
            "La langue préférée du client - applicable uniquement pour les marchés multilingues.",
          marketingOptinContent:
            "Indique si le client a choisi de recevoir les adresses de courriel marketing, c'est-à-dire True ou False. Si elle est omise, la valeur par défaut est False.",
          orderNumberContent:
            "Cette valeur peut être tout identifiant unique que vous utilisez pour identifier les transactions, par ex. TRANSACTION12345678",
          orderValueContent:
            "La valeur de la transaction. Exclure les symboles monétaires, format XXX.YY, par ex. 12.89",
          orderTimestampContent:
            "Date de la transaction complétée, sous forme d'horodatage UNIX, par ex. 1321009871 (la date ne doit pas être antérieure de plus de quatre ans à la date de tracking)",
          optionalText: "Facultatif",
          title: "En-têtes de colonne obligatoires",
          referrerEmailContent:
            "L'adresse de courriel du référent par ex. \"john@doe.com\" (uniquement requis s'il n'y a pas de code pour le bon d'achat).",
          voucherCodeContent:
            "Le code attribué au client ou utilisé par le client au moment de la transaction (uniquement requis s'il n'y a pas l'adresse de courriel du référent).",
          rewardCategory:
            "Si la campagne est configurée avec des catégories de récompenses - c'est-à-dire pour attribuer différents types de récompenses pour différents types de transactions, vous pouvez saisir la catégorie de récompense ici.",
        },
        showRequiredModalColumnsButtonText: "Quelles sont les en-têtes de colonne obligatoires?",
        subtitle:
          "Chaque ligne du fichier CSV doit comprendre au moins le code du bon d'achat utilisé par le client ou l'adresse de courriel du référent.",
      },
      textArea: {
        noLabelText: "no-label",
      },
    },
    campaign: {
      editor: {
        modalTitle: "Campagne: {{campaignName}}",
      },
      header: "Campagne",
      missingCustomFieldText: "Non fourni",
      referredByDateLabel: "Parrainage en cours",
      referredByFieldLabel: "Parrainé par",
      revalidateButtonText: "Revalider",
      subheader: "Date d'Inscription",
      validator: {
        buttonLabel: "Soumettre",
        errorMessage: "Échec car l'utilisateur n'est pas dans l'état conforme",
        formPrompt:
          "Veuillez noter que le traitement de cette demande peut prendre un certain temps. ",
        modalTitle: "Soumettre pour revalidation",
      },
    },
    cis: {
      headerText: "Profil",
      noResultErrorMessage: "Impossible de récupérer le profil CIS",
      retryButtonText: "Réessayer",
    },
    components: {
      multiSelectFilter: {
        header: "Liste des filtres",
        allSelected: "Sélectionnés",
        countSelected: "{{count}} Sélectionné",
      },
    },
    cta: {
      changeMarket: "Changer de marché",
      signOut: "Se déconnecter",
    },
    fileImport: {
      fileSelect: {
        buttonText: "Naviguer",
        infoText: "Télécharger le fichier CSV",
      },
    },
    fileSelect: {
      buttonText: "Naviguer",
      infoText: "Sélectionner un fichier",
    },
    forms: {
      errors: {
        generic: {
          invalid:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard.",
        },
        imageUpload: {
          format: "Veuillez télécharger une image au format png ou jpeg",
          size: "Veuillez télécharger une image plus petite",
        },
        referral: {
          unsuccessful: "La création du parrainage a échoué.",
          pending_count_unsuccessful:
            "Impossible d'obtenir le nombre de parrainages en attente par campagne",
        },
        email: {
          format:
            "L'adresse de courriel que vous avez saisie est invalide. Veuillez verifier qu'elle soit correcte.",
          not_unique:
            "Cette adresse email existe déjà sous un autre programme. Veuillez s'il vous plaît en utiliser une autre.",
          filled: "Veuillez saisir une valeur",
        },
        confirmationCode: {
          invalid:
            "Le code que vous avez saisie est incorrecte, veuillez vérifier votre adresse de courriel et réessayer.",
        },
        passwordConfirmation: {
          doesnt_match:
            "Les mots de passe que vous avez saisis ne correspondent pas, veuillez vous assurer qu'ils sont corrects.",
        },
        password: {
          invalid: passwordFormatError,
          format: passwordFormatError,
        },
        firstName: {
          min_size: "Ce nom n'est pas assez long.",
          invalid: "Êtes-vous certain d'avoir entré votre nom correctement?",
        },
        lastName: {
          invalid: "Êtes-vous certain d'avoir entré votre nom correctement?",
        },
        customField: {
          format: "Ceci n'est pas le bon format.",
          filled: "Veuillez saisir une valeur",
        },
        testInputId: {
          filled: "Veuillez saisir une valeur",
          format: "La valeur n'est pas dans le bon format",
        },
        testAnotherInputId: {
          filled: "Veuillez saisir une autre valeur",
          format: "La valeur n'est pas dans le bon format",
        },
        campaign_id: {
          filled: "Veuillez sélectionner une campagne",
        },
        campaignId: {
          key: "Veuillez sélectionner une campagne",
        },
        registration: {
          not_allowed: "L'utilisateur n'est pas autorisé à s'inscrire en tant que référent.",
        },
        termsOptIn: {
          filled: "Veuillez accepter les conditions",
        },
        emailComplianceOptIn: {
          filled: "Veuillez accepter les conditions de conformité des adresses de courriels",
        },
        marketingOptIn: {
          filled: "Veuillez accepter les termes marketing",
        },
        fileImport: {
          empty: "Le fichier CSV est vide ",
          missing_headers:
            "Il manque des en-têtes au fichier CSV, veuillez revoir les en-têtes de colonnes obligatoires ci-dessus et les inclure comme en-têtes",
          unprocessable:
            "Le fichier CSV est invalide, veuillez revoir les en-têtes de colonnes obligatoires ci-dessous et soumettre à nouveau le fichier",
          upload_error:
            "Le service de téléchargement a renvoyé une erreur. Veuillez réessayer plus tard",
          included_in:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard",
          data_errors:
            "Il y a eu un problème avec les donnés CSV et le fichier n'a pas été téléchargé",
        },
        textImport: {
          empty: "La valeur est vide",
          missing_headers:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard",
          data_errors: "Il y a eu un problème avec les données importées.",
        },
        bulkActions: {
          load_history:
            "Il y a eu un problème de chargement de l'historique. Veuillez réessayer plus tard",
        },
        partnerAssets: {
          partnerAssets:
            "Il y a eu un problème pour soumettre ce formulaire, veuillez réessayer plus tard",
          characterLimit: "Limite de caractères atteinte",
        },
      },
      labels: {
        firstName: "Prènom",
        lastName: "Nom",
        email: "Adresse de courriel",
        orderNumber: "Numéro de commande",
        rewardCategory: "Catégorie de récompense",
        selectRewardCategory: "Choisissez une catégorie de récompense",
      },
      headers: {
        addPartner: "Ajouter un Partenaire",
        editProfile: "Modifier le Profil",
      },
      placeholders: {
        select: "Choisir une option",
      },
      infoText: {
        twitter: "Merci d'entrer votre nom d'utilisateur Twitter",
        instagram: "Merci d'entrer votre nom d'utilisateur Instagram",
        facebook: "Merci d'entrer votre nom d'utilisateur Facebook",
        linkedin: "Merci d'entrer votre nom d'utilisateur LinkedIn",
        youtube: "Merci d'entrer votre nom d'utilisateur YouTube",
        snapchat: "Merci d'entrer votre nom d'utilisateur Snapchat",
        signupReason:
          "Parlez nous de vous/votre entreprise. Merci d'inclure des informations sur votre industrie/secteur et le type de clients que vous avez",
        audienceSize: "En moyenne, combien de visiteurs avez vous sur votre site/ réseaux sociaux",
        audienceLocation: "Sélectionner la localisation principale de votre public",
      },
    },
    fulfilments: {
      header: "Accomplissements",
      tableHeaders: {
        name: "Nom",
        dateEarned: "date d'acquisition",
        source: "source",
        status: "Statut",
      },
      infoBox: "Lorsque vous voyez l’icône <1></1>, vous pouvez la survoler pour en savoir plus.",
      actions: {
        errorRewardEmailResenMessage:
          "Il y a eu un problème avec l'envoi de l'adresse de courriel pour cette récompense",
        resentOption: "Renvoyer l'adresse de courriel de récompense ",
        successRewardEmailResentMessage: "Email de récompense renvoyé",
      },
      paymentProviderStatuses: {
        PAYPAL_BLOCKED: "Le paiement a été bloquée.",
        PAYPAL_CURRENCY_NOT_SUPPORTED_FOR_RECEIVER:
          "Cette devise n’est malheureusement pas prise en charge par le compte sur lequel vous souhaitez effectuer le paiement. Merci de bien vouloir essayer avec une devise différente.",
        PAYPAL_FAILED: "Votre demande de virement n'a pas pu être effectué.",
        PAYPAL_INVALID_EMAIL: "Email invalide",
        PAYPAL_ONHOLD: "Le paiement est en cours d'examen et est en attente.",
        PAYPAL_PENDING: "Le paiement a été reçue et sera traitée sous peu.",
        PAYPAL_PENDING_RECIPIENT_NON_HOLDING_CURRENCY_PAYMENT_PREFERENCE:
          "Ce paiement est en attente de confirmation par le destinataire, qui souhaite vérifier chaque paiement émis à partir de la devise que vous avez choisie. Merci de bien vouloir revenir plus tard pour consulter le statut de votre paiement.",
        PAYPAL_RECEIVER_ACCOUNT_LIMITATION:
          "Raison: Le compte destinataire fait l’objet de restrictions.",
        PAYPAL_RECEIVER_ACCOUNT_LOCKED:
          "Raison: Votre demande de virement n'a pas pu être effectué car le compte destinataire est clôturé ou a été bloqué.",
        PAYPAL_RECEIVER_COUNTRY_NOT_ALLOWED:
          "Raison: Votre demande de virement n'a pas pu être effectué car le compte destinataire est détenu au sein d’une zone non supportée ou restreinte.",
        PAYPAL_RECEIVER_STATE_RESTRICTED:
          "Raison: Votre demande de virement n'a pas pu être effectué car le compte destinataire est détenu au sein d’une zone non supportée ou restreinte.",
        PAYPAL_RECEIVER_UNCONFIRMED: "Raison: Le compte destinataire n’a pas été validé.",
        PAYPAL_RECEIVER_UNREGISTERED: "Raison: Le compte destinataire n’existe pas.",
        PAYPAL_RECEIVER_YOUTH_ACCOUNT:
          "Raison: Votre demande de virement a été refusé pour la raison suivante : destinataire âgé de moins de 18 ans.",
        PAYPAL_RECEIVING_LIMIT_EXCEEDED:
          "Raison: Le destinataire ne peut pas recevoir ce paiement car il dépasse son plafond autorisé.",
        PAYPAL_REGULATORY_BLOCKED:
          "Cette transaction a été refusée pour des raisons de conformité réglementaire.",
        PAYPAL_REFUNDED: "Le paiement a été remboursée.",
        PAYPAL_RETURNED:
          "Raison: Le destinataire n'a pas réclamé la somme versée, elle vous est donc restituée.",
        PAYPAL_REVERSED: "Le paiement a été annulée.",
        PAYPAL_RISK_DECLINE:
          "Raison: Votre demande de virement a été refusé pour la raison suivante : risque de fraude.",
        PAYPAL_UNCLAIMED: "Le bénéficiaire n'a pas de compte PayPal.",
        HYPERWALLET_CREATED: "Cette demande de paiement a été reçue et sera traitée.",
        HYPERWALLET_SCHEDULED: "Cette demande de paiement a été reçue et sera traitée.",
        HYPERWALLET_PENDING_ACCOUNT_ACTIVATION: "En attente d’activation du compte.",
        HYPERWALLET_PENDING_ID_VERIFICATION: "En attente de vérification d’identité.",
        HYPERWALLET_PENDING_TAX_VERIFICATION: "En attente de vérification fiscale.",
        HYPERWALLET_PENDING_TRANSFER_METHOD_ACTION: "En attente de méthode de transfert.",
        HYPERWALLET_PENDING_TRANSACTION_VERIFICATION: "En attente de vérification de transaction.",
        HYPERWALLET_IN_PROGRESS: "Cette demande de paiement a été reçue et sera traitée. ",
        HYPERWALLET_UNCLAIMED: "Non réclamé",
        HYPERWALLET_CANCELLED: "Annulé",
        HYPERWALLET_FAILED:
          "Cette demande de paiement a échoué, alors les fonds n’ont pas été déduits.",
        HYPERWALLET_RECALLED: "Rappelé",
        HYPERWALLET_RETURNED:
          "Le bénéficiaire n’a pas réclamé ce paiement, alors les fonds ont été retournés.",
        HYPERWALLET_EXPIRED: "Expiré",
        HYPERWALLET_CONSTRAINT_VIOLATIONS:
          "Cette demande contient des caractères spéciaux non valides ou un nombre insuffisant de caractères.",
      },
    },
    global: {
      add: "Ajouter",
      back: "Retour",
      cancel: "Annuler",
      close: "Fermer",
      confirm: "Confirmer",
      edit: "Editer",
      email: "Adresse de courriel",
      error: "erreur",
      customers: "Clients",
      download: "Télécharger",
      no: "Non",
      none: "Aucun",
      ok: "Ok",
      or: "ou",
      referrals: "Parrainages",
      reporting: "Rapports",
      save: "Enregistrer",
      search: "Rechercher",
      submitted: "envoyé",
      success: "succès",
      undo: "annuler",
      yes: "Oui",
      apply: "Appliquer",
      clearAll: "Tout effacer",
      deselectAll: "Tout désélectionner",
    },
    languages: {
      ar: "Arabe (ar)",
      ms: "Malais (ms)",
      eu: "Basque (eu)",
      bg: "Bulgare (bg)",
      ca: "Catalán (ca)",
      cs: "Tchèque (cs)",
      "zh-CN": "Chinois - Simplifie (zh-CN)",
      "zh-TW": "Chinois - Traditionnel (zh-TW)",
      da: "Danois (da)",
      nl: "Néerlandais (nl)",
      en: "Anglais (en)",
      "en-US": "Anglais (É.-U.) (en-US)",
      fi: "Finnois (fi)",
      fr: "Français (fr)",
      "fr-CA": "Français - Canada (fr-CA)",
      gl: "Galicien (gl)",
      hu: "Hongrois (hu)",
      de: "Allemand (de)",
      "de-informal": "Allemand - Informel (de-informal)",
      "de-AT-informal": "Allemand - Autriche (de-AT-informal)",
      "de-CH-informal": "Allemand - Suisse (de-CH-informal)",
      el: "Grec (el)",
      id: "Indonésien (id)",
      it: "Italien (it)",
      ja: "Japonais (ja)",
      ko: "Coréen (ko)",
      nb: "Norvégien (nb)",
      pl: "Polonais (pl)",
      pt: "Portugais (pt)",
      "pt-BR": "Portugais - Brésil (pt-BR)",
      ro: "Roumain (ro)",
      ru: "Russe (ru)",
      es: "Espagnol (es)",
      sv: "Suédois (sv)",
      tl: "Tagalog (tl)",
      th: "Thaï (th)",
      tr: "Turc (tr)",
      vi: "Vietnamien (vi)",
    },
    marketSelector: {
      title: "Sélectionner le Marché",
      textInput: {
        label: "Filtrer les marchés",
        placeholder: "Recherche...",
      },
    },
    orders: {
      header: "Commandes",
      tableHeaders: {
        codeUsed: "Code utilisé",
        madeOn: "Adopté Le",
        referralLinked: "Parrainage associé",
        reference: "Référence",
        value: "Valeur",
      },
    },
    partners: {
      programPerformance: {
        downloadCsvButton: {
          loading: "Création en cours, veuillez patienter…",
          text: "Télécharger le Rapport (CSV)",
        },
      },
    },
    referrals: {
      header: "Parrainage effectué",
      tableHeaders: {
        friendName: "nom du référé",
        referredOn: "parrainé le",
        reward: "récompense",
        status: "statut",
      },
      cancelReferralModalText: "Annuler le Parrainage",
      delayedConfirmationModal: {
        cancelReferral: {
          confirmationText: "Oui, annuler le Parrainage",
          title: "Étes-vous certain de vouloir annuler ce Parrainage?",
          undoMessage: "L'annulation a été annulée.",
        },
        closeModalText: "Non, retournez-y!",
        confirmReferral: {
          confirmationText: "Oui, confirmer le parrainage",
          content: "Ce changement est irréversible.",
          title: "Étes-vous certain de vouloir confirmer ce Parrainage?",
          undoMessage: "La confirmation a été annulée.",
        },
        restoreReferral: {
          confirmationText: "Oui, restaurer le Parrainage",
          title: "Étes-vous certain de vouloir restaurer ce parrainage à un statut en attente?",
          undoMessage: "La restauration a été annulé.",
        },
        issueReward: {
          confirmationText: "Oui, émettre la récompense",
          title: "Êtes-vous sûr de vouloir émettre une récompense pour cette parrainage?",
          undoMessage: "La récompense n'a pas été émise.",
        },
        selectRewardCategory: "Sélectionnez une catégorie de récompense",
        selectRewardType: "Sélectionnez une récompense",
        undoCounterText: "trop tard!",
      },
      notifications: {
        errorCancelled: "Il y a eu un problème pour annuler ce parrainage",
        errorConfirming: "Il y a eu un problème pour confirmer ce parrainage",
        errorRevertedToPending:
          "Il y a eu un problème pour restaurer ce parrainage à un statut d'attente",
        successCancelled: "Parrainage annulé avec succès",
        successConfirmed: "Parrainage confirmé avec succès",
        successRevertedToPending: "Parrainage rétabli en attente avec succès",
        noConfirmReferralWarning: "Impossible de confirmer manuellement un parrainage CIS",
        successIssueReward: "Récompense émise avec succès",
        errorIssueReward: "Un problème est survenu lors de l'émission de la récompense",
      },
      optionsLabels: {
        cancelReferral: "Annuler le Parrainage",
        confirmReferral: "Confirmer le Parrainage",
        noRewardCategory: "Ne pas spécifier une catégorie de récompense",
        revertToPending: "Restaurer en attente",
        issueReward: "Émettre une nouvelle récompense",
      },
      ineligibilityReasons: {
        ordered_previously: "Déjà commandé",
        no_referrer: "Pas de référent",
        self_referral: "Auto-référent",
        existing_referrer: "Référent existant",
        referral_limit_reached: "Limite de parrainage atteinte",
        friend_voucher_already_redeemed: "Bon de référés déjà réclamé",
        email_required: "L'adresse de courriel requises",
        name_required: "Nom requis",
        terms_opt_in_required: "Option d'adhésion requis",
        unique_referred_friends_reached: "Limite de parrainage unique atteinte",
      },
    },
    reporting: {
      dashboard: {
        errorLoadingData: "Il y a eu un problème pour charger les données du rapport",
        errorTimeLineData: "Il y a eu un problème pour charger les données chronologiques",
        loadingText: "Chargement",
        modal: {
          title: "Laissez-nous vous aider",
        },
        overview: {
          contactBar: {
            modal: {
              emailSubject: "Contactez-nous",
              firstParagraph:
                "Les account managers de Buyapowa sont des experts en matière de parrainage, et il sont là pour aider votre campagne à atteindre son plein potentiel.",
              reachOutAt: "Contactez-nous",
              secondParagraph:
                "Ils seront heureux de discuter de tout ce qui concerne la mise au point <strong> de votre offre, la promotion de votre campagne </strong> et notre <strong> API d'inscriptions </strong>, ainsi que des conseils <strong> en matière de design, analyses psychologiques </strong> et bien plus encore.",
            },
            firstParagraph: "Se mettre en contact avec votre Success Manager",
            secondParagraph: "Nous pouvons vous aider à améliorer ces chiffres",
          },
          legendText: {
            first: "Vos clients existants",
            second: "Les amis de vos clients",
          },
          metricColumns: {
            confirmedReferrals: "Parrainages confirmés",
            confirmedReferralsTooltipContent:
              "Le nombre de parrainages qui ont été confirmés au cours de la période sélectionnée. Les récompenses ne sont délivrées qu'après la confirmation d'un parrainage.",
            newReferrerRegistrations: "Inscriptions de nouveaux référents",
            newReferrerRegistrationsTooltipContent:
              "Le nombre de clients qui se sont inscrit à votre programme de parrainage au cours de la période sélectionnée.",
            pendingReferrals: "Parrainages en attente",
            pendingReferralsTooltipContent:
              "Le nombre de parrainages en attente qui ont été créés au cours de la période sélectionnée. Un parrainage en attente doit faire l'objet d'une validation supplémentaire avant de devenir un parrainage confirmé.",
            sharers: "Partageurs",
            sharersTooltipContent:
              "Pour qu'un référent soit considéré comme un partageur pendant la période sélectionnée, il devait appuyer sur un bouton de partage ou copier le lien",
            title: "Entre",
            uniqueFriendVisits: "Visites uniques d'un référé",
            uniqueFriendVisitsTooltipContent:
              "Le nombre de référés qui ont visité votre programme après avoir été invités par un référent inscrit",
          },
          totalConfirmedReferrals: "Total des parrainages confirmés",
          totalRegisteredReferrers: "Total des référents inscrits",
          totals: {
            since: "Depuis",
          },
        },
        performanceTiles: {
          confirmedReferrals: "Parrainages confirmés",
          confirmedReferralsContent:
            "Le nombre de clients parrainés qui ont été confirmés en tant que référés (sur la base de vos règles commerciales) au cours de la période sélectionnée.",
          confirmedReferralsPerSharer: "Parrainages confirmés par partageur",
          confirmedReferralsPerSharerContent:
            "Rapport entre le nombre de parrainages qui ont été confirmés pendant cette période et le nombre de clients qui ont partagés au moins une fois.",
          friendVisitsPerSharer: "Visites de référés par partageur",
          friendVisitsPerSharerContent:
            "Rapport entre le nombre de visites de référés (personnes invitées par vos clients) et les clients qui ont partagés au moins une fois.",
          friendVisitsToConfirmedReferral: "Visites de référés en parrainages confirmés",
          friendVisitsToConfirmedReferralContent:
            "Pourcentage de référés (personnes invitées par vos clients) devenus des parrainages confirmés au cours de la période sélectionnée.",
          referrersWhoShared: "Référents qui ont partagés",
          referrersWhoSharedContent:
            "Pourcentage de ceux qui ont ensuite partagés au moins une fois avec leurs amis, parmi tous les clients qui se sont inscrits à votre programme de parrainage. Pour qu'ils soient considérés comme des partageurs, ils doivent avoir appuyés sur le bouton de partage ou copié le lien.",
        },
        tablist: {
          overview: "Aperçu",
          timeline: "Calendrier",
        },
        topbar: {
          buttonDownloadReportLoadingText: "Création en cours, veuillez patienter...",
          buttonDownloadReportText: "Télécharger",
          campaignSelector: {
            campaignHandlerTitle: "Campagne",
            deselectAll: "Tout désélectionner",
            campaignSelectorText: {
              multiple: "Sélection multiple",
              none: "Sélectionner une campagne",
            },
          },
          dateRangeSelector: {
            applyLinkText: "appliquer",
            customRangeSelector: {
              title: "Intervalle sélectionné",
              calendarMonth: {
                sunday: "D",
                monday: "L",
                tuesday: "M",
                wednesday: "M",
                thursday: "J",
                friday: "V",
                saturday: "S",
              },
            },
            helpText: {
              firstLine:
                "Sélectionnez un intervalle de dates personnalisé ou choissisez l'une des options prédéfinies à droite.",
              secondLine:
                "Si votre campagne est nouvelle, les données peuvent prendre un peu de temps pour se normaliser.",
            },
            presetsSelector: {
              lastMonth: "Dernier mois",
              lastSevenDays: "7 derniers jours",
              lastThirtyDays: "30 derniers jours",
              lastYear: "Dernière année",
              thisMonth: "Ce mois",
              thisYear: "Cette année",
              today: "Aujourd'hui",
              yesterday: "Hier",
            },
          },
          downloadErrorContent:
            "Le téléchargement a pris trop de temps et ne s'est pas terminé. Veuillez sélectionner un plus petit intervalle de dates ou réessayer.",
          downloadErrorTitle: "Échec du téléchargement",
          tooltipContent:
            "Ce rapport contient les données de la période que vous avez sélectionnée",
        },
      },
      navigation: {
        noMobileWarningTitle: "Le rapports ne sont pas accessibles sur les appareils mobiles.",
        noMobileWarningSubtitle: "Veuillez revisiter cette page en utilisant votre ordinateur.",
        operationalReports: "Rapports d'activité",
        reportingDashboard: "Tableau de bord des rapports",
        partnerReports: "Partenaires de Marque",
        referrerReports: "Programmes de Parrainage",
        rewardReports: "Distribution Automatisée de Récompenses",
      },
      management: {
        downloadCard: {
          buttonGenerateReport: {
            loading: "Création en cours, veuillez patienter...",
            hasReport: "Télécharger",
            noReport: "Générer & Télécharger",
          },
          generatedText: "Dernière création",
          neverGenaratedText:
            "Jamais généré avant. Selectionner un intervalle de date, puis cliquer sur générer et télécharger. Si le téléchargement échoue, essayer de réduire l'intervalle.",
          generateAgainButtonText: "Générer à nouveau",
          changeDateText:
            "Pour voir différentes dates, selectioner un nouvel intervalle de date, puis cliquer sur générer à nouveau. Si le téléchargement échoue, essayer de réduire l'intervalle.",
          errorSubmittedMessage:
            "Merci de vérifier que la date de commencement est inférieure à la date de fin et que l'intervalle se situe entre les limites autorisées par le rapport.",
        },
        header: "Rapports d'activité",
        navigation: {
          partner: {
            allDistributedVouchers: "Tous les bons d'achat distribués",
            allDistributedVouchersDescription:
              "Une liste de tous les bons d'achat distribués dans cette campagne.",
            allDistributedVouchersRecommendedRange: "Recommandé 1 an d'intervalle",
            customerSignups: "Inscription des clients",
            customerSignupsDescription:
              "Une liste complète de chaque client qui a choisi de devenir référent, comprend les détails marketing, les URL de partage et tous les champs personnalisés.",
            customerSignupsRecommendedRange: "Recommandé 1 an d'intervalle",
            friends: "Référés",
            friendsDescription:
              "Tous les référés qui ont rempli leur coordonnés dans la page d'accueil du référent (le cas échéant), ainsi que les référés qui ont effectués une transaction sur votre site via un parrainage.",
            friendsRecommendedRange: "Recommandé 90 jours d'intervalle",
            friendVouchers: "Bons d'achat des référés",
            friendVouchersDescription:
              "Récupérez tous les codes qui ont été distribués à des référés, y compris les bons d'achat après parrainage.",
            friendVouchersRecommendedRange: "Recommandé 90 jours d'intervalle",
            optOuts: "Les désabonnés",
            optOutsDescription: "Liste d'utilisateurs qui se sont désinscrits.",
            optOutsRecommendedRange: "Recommandé 1 an d'intervalle",
            fulfilments: "Rapport soulignant les actions complétées",
            fulfilmentsDescription:
              "Pour récupérer l’ensemble des événements complétés dans la période sélectionnée.",
            fulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardStreamLegacyReferralData: "Données de parrainage de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Une liste des parrainages (similaire au rapport qui a parrainé qui) formatée d'une façon similaire à ce qui était disponible dans RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommandé 90 jours d'intervalle",
            topReferrers: "Meilleurs Référents",
            topReferrersDescription:
              "Une liste des 100 meilleurs référents de la campagne, classés par nombre de parrainages.",
            topReferrersRecommendedRange: "Recommandé 1 an d'intervalle",
            whoReferredWhom: "Qui a parrainé qui",
            whoReferredWhomDescription:
              "Récupérez une liste de tous les parrainages, le statut du parrainage, la date de la dernière mise à jour des données et si le parrainage a été effectué via pixel ou via bon d'achat.",
            whoReferredWhomRecommendedRange: "Recommandé 90 jours d'intervalle",
          },
          referrer: {
            allDistributedVouchers: "Tous les bons d'achat distribués",
            allDistributedVouchersDescription:
              "Une liste de tous les bons d'achat distribués dans cette campagne.",
            allDistributedVouchersRecommendedRange: "Recommandé 1 an d'intervalle",
            customerSignups: "Inscription des clients",
            customerSignupsDescription:
              "Une liste complète de chaque client qui a choisi de devenir référent, comprend les détails marketing, les URL de partage et tous les champs personnalisés.",
            customerSignupsRecommendedRange: "Recommandé 1 an d'intervalle",
            friends: "Référés",
            friendsDescription:
              "Tous les référés qui ont rempli leur coordonnés dans la page d'accueil du référent (le cas échéant), ainsi que les référés qui ont effectués une transaction sur votre site via un parrainage.",
            friendsRecommendedRange: "Recommandé 90 jours d'intervalle",
            friendVouchers: "Bons d'achat des référés",
            friendVouchersDescription:
              "Récupérez tous les codes qui ont été distribués à des référés, y compris les bons d'achat après parrainage.",
            friendVouchersRecommendedRange: "Recommandé 90 jours d'intervalle",
            optOuts: "Les désabonnés",
            optOutsDescription: "Liste d'utilisateurs qui se sont désinscrits.",
            optOutsRecommendedRange: "Recommandé 1 an d'intervalle",
            fulfilments: "Rapport soulignant les actions complétées",
            fulfilmentsDescription:
              "Pour récupérer l’ensemble des événements complétés dans la période sélectionnée.",
            fulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardChoiceFulfilments: "Rapport sur les choix de récompense",
            rewardChoiceFulfilmentsDescription:
              "Pour récupérer l’ensemble des événements complétés dans la période sélectionnée.",
            rewardChoiceFulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardStreamLegacyReferralData: "Données de parrainage de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Une liste des parrainages (similaire au rapport qui a parrainé qui) formatée d'une façon similaire à ce qui était disponible dans RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommandé 90 jours d'intervalle",
            topReferrers: "Meilleurs Référents",
            topReferrersDescription:
              "Une liste des 100 meilleurs référents de la campagne, classés par nombre de parrainages.",
            topReferrersRecommendedRange: "Recommandé 1 an d'intervalle",
            whoReferredWhom: "Qui a parrainé qui",
            whoReferredWhomDescription:
              "Récupérez une liste de tous les parrainages, le statut du parrainage, la date de la dernière mise à jour des données et si le parrainage a été effectué via pixel ou via bon d'achat.",
            whoReferredWhomRecommendedRange: "Recommandé 90 jours d'intervalle",
          },
          reward: {
            allDistributedVouchers: "Tous les bons d'achat distribués",
            allDistributedVouchersDescription:
              "Une liste de tous les bons d'achat distribués dans cette campagne.",
            allDistributedVouchersRecommendedRange: "Recommandé 1 an d'intervalle",
            customerSignups: "Inscription des clients",
            customerSignupsDescription:
              "Une liste complète de chaque client qui a choisi de devenir référent, comprend les détails marketing, les URL de partage et tous les champs personnalisés.",
            customerSignupsRecommendedRange: "Recommandé 1 an d'intervalle",
            friends: "Référés",
            friendsDescription:
              "Tous les référés qui ont rempli leur coordonnés dans la page d'accueil du référent (le cas échéant), ainsi que les référés qui ont effectués une transaction sur votre site via un parrainage.",
            friendsRecommendedRange: "Recommandé 90 jours d'intervalle",
            friendVouchers: "Bons d'achat des référés",
            friendVouchersDescription:
              "Récupérez tous les codes qui ont été distribués à des référés, y compris les bons d'achat après parrainage.",
            friendVouchersRecommendedRange: "Recommandé 90 jours d'intervalle",
            optOuts: "Les désabonnés",
            optOutsDescription: "Liste d'utilisateurs qui se sont désinscrits.",
            optOutsRecommendedRange: "Recommandé 1 an d'intervalle",
            fulfilments: "Rapport soulignant les actions complétées",
            fulfilmentsDescription:
              "Pour récupérer l’ensemble des événements complétés dans la période sélectionnée.",
            fulfilmentsRecommendedRange: "Recommandé 90 jours d'intervalle",
            rewardStreamLegacyReferralData: "Données de parrainage de RewardStream",
            rewardStreamLegacyReferralDataDescription:
              "Une liste des parrainages (similaire au rapport qui a parrainé qui) formatée d'une façon similaire à ce qui était disponible dans RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommandé 90 jours d'intervalle",
            topReferrers: "Meilleurs Référents",
            topReferrersDescription:
              "Une liste des 100 meilleurs référents de la campagne, classés par nombre de parrainages.",
            topReferrersRecommendedRange: "Recommandé 1 an d'intervalle",
            whoReferredWhom: "Qui a parrainé qui",
            whoReferredWhomDescription:
              "Récupérez une liste de tous les parrainages, le statut du parrainage, la date de la dernière mise à jour des données et si le parrainage a été effectué via pixel ou via bon d'achat.",
            whoReferredWhomRecommendedRange: "Recommandé 90 jours d'intervalle",
          },
        },
        selectTitle: "Campagne",
      },
    },
    rewards: {
      header: "Récompenses",
      tableHeaders: {
        address: "Adresse",
        dateEarned: "Date d'acquisition",
        item: "Produit",
        source: "Source",
        status: "Statut",
      },
      actions: {
        claimSubmitted: "Demande soumise",
        clickToClaimOption: "cliquez pour réclamer",
        errorRewardEmailResenMessage:
          "Il y a eu un problème lors de l'envoi de l'adresse de courriel pour cette récompense.",
        errorSubmittedMessage: "Il y a eu un problème dans la mise à jour de cette demande",
        resentOption: "Renvoyer l'adresse de courriel de récompense",
        successRewardEmailResentMessage: "Email de récompense renvoyé",
        successSubmittedMessage: "Demande soumise avec succès",
      },
    },
    statuses: {
      cancelled: "annulé",
      complete: "complété",
      failed: "échoué",
      fulfilled: "complété",
      pending: "en attente de validation",
      processed: "traité",
      processing: "en cours",
      redeemed: "utilisé",
      waiting: "en attente",
      paused: "en pause",
    },
    subscriptions: {
      header: "Abonnements",
      tableHeaders: {
        activationDate: "Date d'activation",
        identifier: "Identifiant",
        plan: "Plan",
        status: "Statut",
      },
    },
    partnerProfile: {
      profile: "Profil",
      displayName: "Afficher le nom",
      displayNameTooltip:
        "C'est ainsi que le nom de votre marque/entreprise sera affiché sur votre page publique",
      quote: "Votre slogan",
      quoteTooltip:
        "Veuillez entrer une courte description de votre marque/entreprise et la raison pour laquelle vous êtes partenaire de ce programme. Cela sera affiché sur votre page de destination d'audience.",
      assets: "Contenus",
      informationContent: "CONTENUS INFORMATIF",
      assetsDescription:
        "Complétez votre profil en ajoutant des éléments descriptifs et visuels (par exemple, nom, profil, bannière et votre slogan)",
      addAssets: {
        prompt: "Ajouter du contenu",
        editPrompt: "Modifier le contenu",
        sectionOneHeading: "Éléments descriptifs",
        sectionOneDescription: "Veuillez ajouter votre nom et slogan",
        sectionTwoHeading: "Éléments visuels",
        sectionTwoDescription:
          "Veuillez télécharger votre logo et l'image de la bannière pour votre page publique",
        logoImporterHeader: "Téléchargez votre logo ici",
        logoImporterInfo: "Recommandations relatives aux dimensions: 48 x 48 pixels",
        bannerImporterHeader: "Téléchargez votre bannière ici",
        bannerImporterInfo: "Recommandations relatives aux dimensions: 350 x 415 pixels",
        importerButtonText: "Parcourir les fichiers",
        successTitle: "Vos éléments ont été publiés avec succès",
        successMessage:
          "Vous pouvez maintenant afficher et modifier votre profil et explorer d'autres fonctionnalités",
        congratulations: "Félicitations !",
        quotePlaceholder:
          "Veuillez entrer une courte description de votre marque/entreprise et la raison pour laquelle vous êtes partenaire de ce programme",
      },
    },
    userProfile: {
      confirmAlreadyExistingFriend: {
        content:
          "Veuillez confirmer que vous souhaitez traquer un nouveau parrainage vers ce référé existant",
      },
      createReferralButtonText: "Créer un parrainage",
      createReferralModal: {
        title: "Créer un parrainage",
        friendExists: "Ce référé existe déjà",
      },
      errorLoadingText: "Il y a eu un problème dans le chargement des données de l'utilisateur",
      editDetailsText: "Modifier les détails",
      loadingText:
        "Cette page contient de nombreux parrainages et peut prendre un certain temps à se charger",
      identifier: "Identifiant",
      issueNewRewardText: "Délivrer des nouvelles récompenses",
      issueNewRewardModal: {
        campaignText: "Campagne",
        errorMessage:
          "Il y a eu un problème dans la création de cette récompense, veuillez réessayer plus tard.",
        errorLimitMessage:
          "Vous avez atteint la limite des récompenses que vous pouvez délivrer sur une période de 24h",
        prompt: "Êtes-vous certain de vouloir délivrer cette récompense ?",
        recipientText: "Destinataire",
        rewardInfo: {
          isCustomer:
            "Si cette campagne récompense le référé qui a été parrainé, vous devrez lui délivrer également une récompense.",
          isExternalUser:
            "Si cette campagne récompense le référent qui a effectué le parrainage, vous devrez lui délivrer également une récompense.",
        },
        rewardTypes: {
          archived: "Archivé",
          inactive: "Inactif",
        },
        rewardText: "Récompense",
        subtitle: "Seulement 10 récompenses par jour peuvent être délivrées manuellement.",
        successMessage: "Récompense délivrée avec succès à ",
        title: "Délivrance de la récompense ",
      },
      firstSeen: "première connexion",
      lastSeen: "dernière connexion",
      shareURL: "Partager l'URL",
      successReferralCreationMessage: "Le parrainage a été créé avec succès.",
      unsubscribeFromEmailsText: "Se désabonner les adresses de courriel",
      unsubscribeModal: {
        closeModalText: "Non, Annuler",
        errorMessage: "Il y a eu un problème dans la mise à jour de cet utilisateur",
        prompt: "Oui, se désabonner",
        successMessage: "Vous vous êtes désabonné avec succès",
        title:
          "Êtes-vous certain de vouloir désabonner {{fullName}} de tous les adresse de courriel?",
      },
    },
    userResults: {
      noResultMessage:
        "Désolé, nous avons trouvé <strong>0 résultat</strong> pour <strong>{{searchTerm}}</strong>. Veuillez essayer une autre recherche.",
      tableHeader: {
        name: "Nom",
        email: "Adresse de courriel",
        identifier: "Identifiant",
        type: "type",
        referrals: "parrainages",
      },
      userType: {
        friend: "référé",
        referrer: "référent",
      },
    },
    users: {
      navigation: {
        bulkActions: "Actions groupées",
        customerManagement: "Gestion client",
        partnerManagement: "Gestion partenaire",
      },
    },
    userSearch: {
      addReferrer: {
        prompt: "Ajouter un référent",
        modal: {
          successMessage: "Référent créé avec succès",
          form: {
            selectCampaignText: "Sélectionnez une campagne",
            emailComplianceLabelText:
              "Cochez cette case pour confirmer que ce référent accepte qu'il n'envoie des messages de parrainage qu'aux personnes avec lesquelles il a un relation personelle ou familiale.",
            marketingOptInLabelText:
              "Cochez cette case pour confirmer que vous avez le consentement de ce référent à l'inscrire à votre campagne marketing",
            termAndConditionsLabelText:
              "Cochez cette case pour confirmer que ce référent a accepté les conditions générales.",
          },
        },
      },
      searchResult: "{{count}} résultats",
      searchResult_one: "{{count}} résultat",
      searchInstructionPrompt:
        "Recherchez le nom du client, son adresse de courriel, son numéro de commande ou tout autre champ personnalisé que vous avez configuré.",
    },
    vouchers: {
      header: "Bons d'achat",
      tableHeaders: {
        manuallyIssued: "Délivré manuellement",
        obtainedThrough: "Obtenu par",
        redeemed: "Réclamé?",
        redeemedBy: "Réclamé par",
        voucher: "Bon d'achat",
      },
    },
    paragon: {
      integrations: "Intégrations",
      connectionError: "Il y a eu un problème de connexion à ce service.",
      connected: "Connecté",
      notConnected: "Non connecté",
      connectedDescription:
        "Gérez votre campagne plus efficacement en naviguant dans les paramètres d'intégration.",
      notConnectedDescription:
        "Connectez et synchronisez vos {{integrationName}} comptes, contacts et prospects.",
      connect: "Connecter",
      settings: "Paramètres d'intégration",
    },
  },
};
