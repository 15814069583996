import React, { useEffect, useState } from "react";

import { CSVHeaderTable, DetailList, DetailListItem, InfoPanel, Modal, Statuses } from "bp-ui";
import Market from "models/Market";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";

import FileImport from "components/bulk_actions/file_import/FileImport";
import SignupsHistory from "components/bulk_actions/signups/signups_history/SignupsHistory";
import { CSVDownloadButton } from "components/csv_download_button/CSVDownloadButton";
import addNotification from "components/notifications/Notifications";

import "./signups.scss";

const Signups = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const [showRequiredColumnsModal, setShowRequiredColumnsModal] = useState(false);
  const [checkHistory, setCheckHistory] = useState(true);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignSelectPlaceholder, setCampaignSelectPlaceholder] = useState(
    t("bulkActions.signups.campaignSelectPlaceholder")
  );
  const [importList, setImportList] = useState([]);
  const [importListLoadingError, setImportListLoadingError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const campaigns = selectedMarket.visibleCampaigns().map((campaign) => ({
        value: campaign.id,
        name: campaign.name,
      }));
      setCampaignList(campaigns);
      if (campaigns.length === 0) {
        setCampaignSelectPlaceholder(t("bulkActions.signups.campaignListPlaceholder"));
      }

      try {
        const signupHistory = await selectedMarket.imports.getList("signups");
        setImportList(signupHistory);
      } catch (err) {
        setImportListLoadingError(true);
      }
    };

    getData();
  }, [t, checkHistory, selectedMarket?.campaigns, selectedMarket?.imports]);

  const toggleModal = () => {
    setShowRequiredColumnsModal(!showRequiredColumnsModal);
  };

  const onImportSuccess = () => {
    addNotification.success(t("global.success"), t("bulkActions.successNotification"));
    setCheckHistory(!checkHistory);
  };

  const ModalContent = () => {
    const headers = [
      "Email",
      "FirstName",
      "LastName",
      "CustomField",
      "CustomField_key",
      "ClientData_key",
      "Locale",
    ];
    const filename = "signups.csv";

    return (
      <>
        <DetailList>
          <DetailListItem
            title="Email"
            description={t("bulkActions.signups.modal.required.emailDescription")}
          />
          <DetailListItem
            title="FirstName"
            description={t("bulkActions.signups.modal.required.firstNameDescription")}
          />
        </DetailList>

        <hr className="border-lighter-grey border-1/2 my-8" />

        <h3 className="text-xl text-dark-blue font-normal mb-8">
          {t("bulkActions.signups.modal.optional.header")}
        </h3>

        <DetailList>
          <DetailListItem
            title="LastName"
            description={t("bulkActions.signups.modal.optional.lastNameDescription")}
          />

          <DetailListItem
            title="CustomField"
            description={t("bulkActions.signups.modal.optional.customFieldDescription")}
          />

          <DetailListItem
            title="CustomField_key"
            description={t("bulkActions.signups.modal.optional.customFieldsDescription")}
          />

          <DetailListItem
            title="ClientData"
            description={t("bulkActions.signups.modal.optional.clientDataDescription")}
          />

          <DetailListItem
            title="Locale"
            description={t("bulkActions.signups.modal.optional.localeDescription")}
          />
        </DetailList>

        <hr className="border-lighter-grey border-1/2 my-8" />

        <h3 className="text-xl text-dark-blue font-normal mb-8">
          {t("bulkActions.signups.modal.response.header")}
        </h3>

        <DetailList>
          <DetailListItem
            title="ShareURL"
            description={t("bulkActions.signups.modal.response.shareUrlDescription")}
          />

          <DetailListItem
            title="DashboardURL"
            description={t("bulkActions.signups.modal.response.dashboardUrlDescription")}
          />

          <DetailListItem
            title="Status"
            description={t("bulkActions.signups.modal.response.statusDescription")}
          />
        </DetailList>

        <InfoPanel statusType={Statuses.DEFAULT} className="mb-4">
          <p className="font-semibold text-sm m-0">{t("bulkActions.downloadCsvDescription")}</p>
          <CSVHeaderTable headers={headers} />
        </InfoPanel>

        <div className="flex mt-4">
          <CSVDownloadButton filename={filename} headers={headers} data={[]} icon={<FaDownload />}>
            {t("bulkActions.downloadCsvButtonText")}
          </CSVDownloadButton>
        </div>
      </>
    );
  };

  return (
    <div className="c-signups card">
      <h2 className="card__title">{t("bulkActions.signups.header")}</h2>

      <p className="mb-8">
        {t("bulkActions.signups.subtitle")} <br />
        <strong>{t("bulkActions.signups.limit")}</strong> {t("bulkActions.notification")}
      </p>

      <a
        href="/required-headers"
        className="underline font-semibold text-mid-blue"
        onClick={(e) => {
          e.preventDefault();
          toggleModal();
        }}
      >
        {t("bulkActions.signups.modal.openButtonText")}
      </a>

      <hr className="border-lighter-grey border-1/2 my-8" />

      <FileImport
        selectedMarket={selectedMarket}
        importType="signups"
        buttonLabel={t("bulkActions.signups.fileImport.buttonLabel")}
        checkBoxOptionKey="send_emails"
        checkBoxDefault
        checkBoxLabel={t("bulkActions.signups.fileImport.checkboxLabel")}
        onImportSuccess={onImportSuccess}
        selectOptions={campaignList}
        selectPlaceholder={campaignSelectPlaceholder}
        selectKey="campaign_id"
      />

      <Modal
        className="top-24 max-w-1/2"
        onClose={() => toggleModal()}
        open={showRequiredColumnsModal}
        title={t("bulkActions.signups.modal.title")}
        closeButtonAriaLabel={t("global.close")}
      >
        <ModalContent />
      </Modal>

      <SignupsHistory
        importList={importList}
        timezoneOffset={selectedMarket.timezone_offset}
        importListLoadingError={importListLoadingError}
      />
    </div>
  );
};

Signups.propTypes = {
  selectedMarket: PropTypes.instanceOf(Market).isRequired,
};

export default Signups;
